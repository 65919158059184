import './loading.scss'

/**
 * @description 创建节点
 * @param{*} removeTime 销毁节点时间 3000/自定义
 */
let node = document.createElement("div");
let childNode = document.createElement("div");
let childNode2 = document.createElement("span");
node.setAttribute("class", "showbox");
childNode.setAttribute("class", "loader");
childNode2.setAttribute("class", "loading-custom-text");

// 创建节点
function loadingNode (key, text, code) {
  if (code == 'error') {
    childNode.innerHTML = ""
  } else {
    childNode.innerHTML = `<svg class="circular" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
  </svg>`
  }

  childNode2.innerHTML = text
  node.appendChild(childNode);
  childNode.appendChild(childNode2);
  document.body.appendChild(node);
  node.style['display'] = 'block'
  // if (key) {
  //   const time = Number(key)
  //   loadingClear(time)
  // }
}
// 消除节点
/**
 *  
 * @param {*} time 时间
 */
function loadingClear (time) {
  let removeTime
  if (time || time === 0) {
    removeTime = time
  } else {
    removeTime = 2500
  }
  setTimeout(() => {
    node.style['display'] = 'none'
    // node.remove()
  }, removeTime)
}
export { loadingNode, loadingClear }