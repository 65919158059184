import service from '../required/axios'
export default {
  // 认证 - tiktok认证登录
  certificationTiTok (data) {
    return service({
      url: 'auth.tiktok_auth_login',
      method: "post",
      data: data
    })
  },
  // 认证 - tiktok认证登录
  applyTiTok (data) {
    return service({
      url: 'auth.tiktok_apply',
      method: "post",
      data: data
    })
  },
}