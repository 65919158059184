/**
 * @description 世界时区设置
 * America/New_York 纽约
 * Australia/Sydney 悉尼
 * Asia/Tokyo 东京
 * Asia/Shanghai 北京
 * Asia/Seoul 首尔
 * Asia/Jerusalem 以色列
 * pt-br 巴西
 * Europe/Athens 阿莫斯特丹 【荷兰】
 */
const timeZone = 'America/New_York'
// moment().tz(this.timeZone)
export {timeZone}