
import service from '@/required/axios'
export default {
  downloadApi () {
    return service({
      url: '/system.version',
      method: "get",
    })
  },

}