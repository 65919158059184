// 荷兰语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitel: 'Bericht',
    IMErrorMessage: 'IM-verbinding mislukt, klik om het opnieuw te proberen! ',
  },
  "appWebSocketDetails": {
    sendOption: 'Verzenden',
    send: 'verzenden',
    fileFail: "Foto's uploaden en formatteren",
    IMportError: 'Momenteel niet verbonden, probeer het opnieuw! ',
    IMErrorMessage: 'IM-verbinding mislukt, probeer het opnieuw! ',
    retryBtn: 'Opnieuw proberen',
  },
  "appLoginIndex": {
    navTitle: 'Inloggen',
    projectName: 'Winkel beheren',
    formLabel1: 'E-mail',
    formLabel2: 'wachtwoord',
    placeholder: 'Voer in',
    hint: 'Als je geen account hebt',
    clickRegister: 'Klik om te registreren',
    forgetPass: 'wachtwoord vergeten',
    optionBtn: 'Inloggen',
    validateError: 'Vul de invoer in! ',
  },
  "appForgetPassIndex": {
    navTitle: 'Wachtwoord vergeten',
    formLabel1: 'Inlogmailbox',
    formLabel2: 'Verificatiecode',
    formLabel3: 'Nieuw wachtwoord',
    formLabel4: 'Wachtwoord bevestigen',
    formHint1: 'Voer uw e-mailadres voor inloggen in',
    formHint2: 'Voer een nieuw wachtwoord in',
    formHint3: 'Voer de verificatiecode in',
    formHint4: 'Voer uw wachtwoord twee keer in',
    formOption: 'Verzenden',
    sendOption: 'Aftellen:',
    optionsBtn: 'Bevestigen',
  },
  'footer': {
    home: 'winkel',
    project: 'product',
    info: 'Bericht',
    order: 'bestelling',
    user: 'mijn',
  },
  'setDrawer': {
    label1: 'Meertalige instellingen',
    label2: 'Winkelregistratie',
    label3: 'Winkel inloggen',
    label4: 'Klantenservice',
    label5: 'Android-applicatie downloaden',
    label6: 'ios-applicatie downloaden',
    optionsBtn: 'Annuleren',
  },
  'appHomeIndex': {
    accountLabel1: 'Verkoop',
    accountLabel2: 'verkeer',
    cardLabel1: 'Gegevensoverzicht',
    cardLabel2: 'Gegevensrapport',
    cardLabel3: 'Bedragenoverzicht',
    ech1Title: 'Totale omzet',
    ech2Title: 'Totaal aantal bestellingen',
    ech3Title: 'Winkelverkeer',
    echBuoy1Title: 'Totale omzet',
    echBuoy2Title: 'Totaal aantal bestellingen',
    echBuoy3Title: 'Winkelverkeer',
    dataCardTitle1: 'Verkoop',
    dataCardTitle2: 'Verkeer',
    dataCardTitle3: 'Totaal aantal bestellingen',
    dataCardTitle4: 'Totaal uitstaand bedrag',
    dataCardDayTags: 'Vandaag',
    dataYesterTags: "Gegevens van gisteren",
    dataYesterTags1: "Aantal niet-gecontroleerde bestellingen",
    cardMenusLabel1: 'Belasting',
    cardMenusLabel2: 'Opgeslagen waarde',
    cardMenusLabel3: 'Opname',
    cardMenusLabel4: 'Uitnodiging delen',
    cardMenusLabel5: 'Productlijst',
    cardMenusLabel6: 'Bestellijst',
    cardMenusLabel7: 'Bedrijfsrapport',
    taxPopupTitle: 'Momenteel belasting betalen:',
    popupBtn: 'Ga belasting betalen',
    cardStateLabel1: 'Saldo',
    cardStateLabel2: 'Intrekking',
    cardStateLabel3: 'Inkomen',
    cardStateLabel4: 'Team',
    cardSellHotTitle: 'Top 10 meest verkochte artikelen',
    salesLabel: 'Verkoop:',
  },
  'appSetLanguage': {
    navTitle: 'Meertalige instellingen',
    affirm: 'bevestigen',
  },
  "appServiceIndex": {
    navTitle: 'Klantenservice',
    customerService: 'Exclusieve klantenservice',
    helloTitle: 'Hallo',
    welcomeTitle: 'Ik ben van jou<, je kunt contact met mij opnemen via de volgende kanalen',
    iconInformation: 'Selecteer communicatiesoftware voor advies',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'KakaoTalk',
    signal: 'Signal'
  },
  'appUserIndex': {
    blanceLabel: 'Balans',
    blanceOption1: 'Opnemen',
    blanceOption2: 'Winkelwaarde',
    incomeLabel1: 'Cumulatief inkomen',
    incomeLabel2: 'Cumulatieve opnames',
    listLabel1: 'Winkelinformatie',
    listLabel2: 'Instellingen voor opnamemethode',
    listLabel3: 'Fondsrecord',
    listLabel4: 'Bedrijfsrapport',
    listLabel5: 'Kortingsactiviteit',
    listLabel7: 'Lening',
    listLabel8: 'Ga naar het winkelcentrum',
    listLabel9: 'Toepassing downloaden',
    listLabelLast: 'Afsluiten',
  },
  'downloadAppIndex': {
    navTitle: 'Downloaden',
    downloadAndroidApp: "Android-download",
    downloadIosApp: "IOS-download",
  },
  "appDiscountActivity": {
    navTitle: 'Kortingsactiviteit',
    popupLabel1: 'bedrag',
    popupPlace1: 'Voer het bedrag in',
    popupLabel2: 'Korting',
    popupPlace2: 'Voer korting in',
    popupOption: 'Bevestigen',
    listLabel1: 'Benodigd bedrag:',
    listLabel2: 'Kortingsratio:',
    listLabel3: 'Kortingstijd:',
    dialogTitle: "Bevestig of u wilt verwijderen!",
    dialogAffirm: 'Bevestigen',
    dialogClose: "Annuleren",
  },
  "appInviteIndex": {
    navTitle: 'Nodig nieuwe activiteiten uit',
    headerTitle: 'Nodig vrienden uit om beloningen te verdienen',
    copyOption: 'kopiëren',
    copySuccess: 'Kopiëren succesvol',
    inviteTitle: 'Totaal aantal uitnodigingen',
    inviteLabel1: 'Aantal uitgenodigde personen:',
    inviteLabel2: 'Totale commissie:',
    ruleTitle: 'Uitnodigingsregel',
    ruleHint1: ' 1. De uitnodiger kan vrienden uitnodigen om de registratie te voltooien door de promotielink te delen, en de uitgenodigde vrienden worden aan u gekoppeld;',
    ruleHint2: ' 2. Nadat u gebruikers hebt uitgenodigd om de betaling te voltooien, kunt u een deel van hun winst ontvangen;',
    ruleHint3: ' 3. Alleen uitnodigingsbeloningen op het derde niveau worden ondersteund en de beloningsverhouding is: <%, >%, @% ',
  },
  "appInviteUserRecordIndex": {
    navTitle: 'Gebruikersrecord uitnodigen',
    recordTitle1: 'Totaal aantal gebruikers',
    recordTitel2: 'Niveau 1',
    recordTitle3: 'Niveau 2',
    recordTitle4: 'Niveau 3',
    tabLabel1: 'alles',
    tabLabel2: 'Niveau 1',
    tabLabel3: 'Tweede niveau',
    tabLabel4: 'Niveau 3',
    listLabel1: 'Niveau:',
    listLabel2: 'Winst:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: 'Inkomstenrecord voor uitnodigingen',
    recordTitle1: 'Totale omzet',
    recordTitle2: 'Niveau 1',
    recordTitle3: 'Niveau 2',
    recordTitle4: 'Niveau 3',
    tabLabel1: 'alles',
    tabLabel2: 'Niveau 1',
    tabLabel3: 'Tweede niveau',
    tabLabel4: 'Niveau 3',
    listLabel1: 'Winst:',
    listLabel2: 'Aandeel:',
  },
  "appWithdrawIndex": {
    navTitle: 'Intrekking',
    currencyType1: 'fiatvaluta',
    currencyType2: 'virtuele valuta',
    formLabel1: 'Valutatype:',
    formPlace1: 'Selecteer valutatype',
    formLabel2: 'Selecteer valuta:',
    formPlace2: 'Selecteer valutatype',
    formLabel3: 'Opnamebedrag:',
    formPlace3: 'Voer het opnamebedrag in',
    formLabel4: 'Wachtwoord intrekken:',
    formPlace4: 'Voer het opnamewachtwoord in:',
    balanceLabel: 'Huidig ​​saldo:',
    amountLabel: 'Werkelijke aankomst:',
    optionsBtn: 'Verzenden',
    dialogClose: 'Annuleren',
    dialogAffirm: 'Bevestigen',
    currencySelectError: 'Selecteer valutatype',
    currencyError: 'Selecteer valuta',
    affirmDialogDialogTitle1: 'U heeft geen betalingswachtwoord ingesteld. Wil je het instellen? ',
    affirmDialogDialogTitle2: 'Je hebt het opnamekanaal niet ingesteld, wil je dit instellen? ',
    affirmDialogBtn2: 'Annuleren',
    affirmDialogBtn1: 'Bevestigen',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'Instelling van de opnamemethode',
    currencyType1: 'fiatvaluta',
    currencyType2: 'virtuele valuta',
    formLabel1: 'Valutatype:',
    formPlace1: 'Selecteer valutatype',
    formLabel2: 'Selecteer valuta:',
    formPlace2: 'Selecteer valutatype',
    addressLabel: 'Adres:',
    addressPlace: 'Voer het adres van de virtuele valuta in',
    hintLabel: 'Belangrijke tip',
    hintValue: 'Alleen ¥ activa overdragen',
    optionsBtn: 'Verzenden',
    dialogClose: 'Annuleren',
    dialogAffirm: 'Bevestigen',
    currencySelectError: 'Selecteer valutatype',
    currencyError: 'Selecteer valuta',
    currencyFail: 'Nog niet geopend',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'echte naam',
    formPlace1: 'Vul uw echte naam in',
    formLabel2: 'E-mail',
    formPlace2: 'Voer uw e-mailadres in',
    formLabel3: 'Mobiel telefoonnummer',
    formPlace3: 'Vul uw mobiele telefoonnummer in',
    formLabel4: 'PIX-type',
    formPlace4: 'Selecteer PIX-type',
    formLabel5: 'PIX-account',
    formPlace5: 'Voer uw PIX-accountnummer in',
    formLabel6: 'Banknaam',
    formPlace6: 'Voer de naam van de bank in',
    formLabel7: 'Bankrekening',
    formPlace7: 'Voer uw bankrekening in',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'Land/regio',
    formPlace1: 'Voer land/regio in',
    formLabel2: 'naam',
    formPlace2: 'Voer een naam in',
    formLabel3: 'Achternaam',
    formPlace3: 'Vul uw achternaam in',
    formLabel4: 'snel',
    formPlace4: 'Voer snel in',
    formLabel5: 'Banknaam',
    formPlace5: 'Voer de naam van de bank in',
    formLabel6: 'iban',
    formPlace6: 'Vul iban in',
    formLabel7: 'Mobiel telefoonnummer',
    formPlace7: 'Vul uw mobiele telefoonnummer in',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'Land/regio',
    formPlace1: 'Voer land/regio in',
    formLabel2: 'naam',
    formPlace2: 'Voer een naam in',
    formLabel3: 'Achternaam',
    formPlace3: 'Vul uw achternaam in',
    formLabel4: 'snel',
    formPlace4: 'Voer snel in',
    formLabel5: 'ach',
    formPlace5: 'Voer een ach in',
    formLabel6: 'Banknaam',
    formPlace6: 'Voer de naam van de bank in',
    formLabel7: 'Bankrekening',
    formPlace7: 'Voer uw bankrekening in',
    formLabel8: 'Provincie of staat',
    formPlace8: 'Vul de provincie of staat in',
    formLabel9: 'Mobiel telefoonnummer',
    formPlace9: 'Vul uw mobiele telefoonnummer in',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'Land/regio',
    formPlace1: 'Voer land/regio in',
    formLabel2: 'naam',
    formPlace2: 'Voer een naam in',
    formLabel3: 'Achternaam',
    formPlace3: 'Vul uw achternaam in',
    formLabel4: 'Bankcode',
    formPlace4: 'Vul de bankcode in',
    formLabel5: 'Banknaam',
    formPlace5: 'Voer de naam van de bank in',
    formLabel6: 'Bankrekening',
    formPlace6: 'Voer uw bankrekening in',
    formLabel7: 'Mobiel telefoonnummer',
    formPlace7: 'Vul uw mobiele telefoonnummer in',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'Land/regio',
    formPlace1: 'Voer land/regio in',
    formLabel2: 'naam',
    formPlace2: 'Voer een naam in',
    formLabel3: 'Achternaam',
    formPlace3: 'Vul uw achternaam in',
    formLabel4: 'zengin',
    formPlace4: 'Voer zengin in',
    formLabel5: 'Banknaam',
    formPlace5: 'Voer de naam van de bank in',
    formLabel6: 'Bankrekening',
    formPlace6: 'Voer uw bankrekening in',
    formLabel7: 'Provincie of staat',
    formPlace7: 'Vul de provincie of staat in',
    formLabel8: 'Mobiel telefoonnummer',
    formPlace8: 'Vul uw mobiele telefoonnummer in',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'Land/regio',
    formPlace1: 'Voer land/regio in',
    formLabel2: 'naam',
    formPlace2: 'Voer een naam in',
    formLabel3: 'Achternaam',
    formPlace3: 'Vul uw achternaam in',
    formLabel4: 'BSB',
    formPlace4: 'Voer alstublieft BSB in',
    formLabel5: 'Banknaam',
    formPlace5: 'Voer de naam van de bank in',
    formLabel6: 'Bankrekening',
    formPlace6: 'Voer uw bankrekening in',
    formLabel7: 'Provincie of staat',
    formPlace7: 'Vul de provincie of staat in',
    formLabel8: 'Mobiel telefoonnummer',
    formPlace8: 'Vul uw mobiele telefoonnummer in',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'naam',
    formPlace1: 'Voer een naam in',
    formLabel2: 'Achternaam',
    formPlace2: 'Vul uw achternaam in',
    formLabel3: 'Banknaam',
    formPlace3: 'Voer de naam van de bank in',
    formLabel4: 'Bankrekening',
    formPlace4: 'Voer uw bankrekening in',
    formLabel5: 'Mobiel telefoonnummer',
    formPlace5: 'Vul uw mobiele telefoonnummer in',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Banknaam',
    formPlace1: 'Voer de naam van de bank in',
    formLabel2: 'Account',
    formPlace2: 'Voer uw account in',
    formLabel3: 'clabe-account',
    formPlace3: 'Voer alstublieft uw clabe-account in',
    formLabel4: 'Mobiel telefoonnummer',
    formPlace4: 'Vul uw mobiele telefoonnummer in',
  },
  'appStatisticsReportIndex': {
    navTitle: 'Operationeel rapport',
    cardLabel1: 'Totale omzet',
    cardLabel2: 'Totaal aankoopbedrag',
    cardLabel3: 'Totale winst',
    cardLabel4: 'Totaal aantal bestellingen',
    listLabel1: 'Tijd:',
    listLabel2: 'Bestelling:',
    listLabel3: 'Verkoop:',
    listLabel4: 'Aankoopbedrag:',
    profitLabel: 'winst',
    searchDateTitle: 'Documenttijd',
    searchIntervalTitle: 'Intervaltijd',
    searchStartTimePlace: 'Starttijd',
    searchEndTimePlace: 'Eindtijd',
    searchOptions1: 'Reset',
    searchOptions2: 'Bevestigen',
    searchDateAffirm: 'bevestigen',
    searchDateClose: 'Annuleren',
    searchIntervalLabel1: 'alles',
    searchIntervalLabel2: 'Vandaag',
    searchIntervalLabel3: 'gisteren',
    searchIntervalLabel4: 'Deze week',
    searchIntervalLabel5: 'deze maand',
    searchIntervalLabel6: 'dit jaar',
    searchIntervalOptions: 'Annuleren',

  },
  "appAmountRechargeIndex": {
    navTitle: 'Opgeslagen waarde',
    currencyType1: 'fiatvaluta',
    currencyType2: 'virtuele valuta',
    formLabel1: 'Valutatype:',
    formPlace1: 'Selecteer valutatype',
    formLabel2: 'Selecteer valuta:',
    formPlace2: 'Selecteer valutatype',
    formLabel3: 'Opgeslagen waardebedrag:',
    formPlace3: 'Voer het opgeslagen waardebedrag in',
    formLabel4: 'Oplaadmethode',
    formPlace4: 'Selecteer de opslagmethode:',
    balanceLabel: 'Huidig ​​saldo:',
    amountLabel: 'Werkelijke aankomst:',
    optionsBtn: 'Verzenden',
    dialogClose: 'Annuleren',
    dialogAffirm: 'Bevestigen',
    currencySelectError: 'Selecteer valutatype',
    currencyError: 'Selecteer valuta',
    successCopy: 'Succesvol kopiëren',
    hintLabel: "Belangrijke herinnering:",
    hintValue: 'Alleen overdracht van ¥ activa is toegestaan',
    legalHint: 'Neem contact op met de klantenservice!'
  },
  'appShopInfoIndex': {
    navTitle: 'Winkelinformatie',
    navEditText: 'Bewerken',
    formLabel1: 'Afbeelding opslaan',
    formLabel2: 'winkelnaam',
    formLabel3: 'Inlog-e-mail',
    formLabel4: 'uitnodigingscode',
    formLabel5: 'Inlogwachtwoord',
    formLabel6: 'Betalingswachtwoord',
    formLabel7: 'Beschrijving bericht',
    formLabel8: 'Achtergrondafbeelding opslaan',
    formLabel9: 'Marktachtergrondafbeelding',
    formLabel10: 'Certificaatinformatie',
    formHint1: 'Documentvoorzijde',
    formHint2: 'De achterkant van het certificaat',
    fileFail: 'Upload afbeeldingen en formaat',
    optionsBtn: 'Verzenden',
  },
  'appShopInfoEdit': {
    navTitle: 'Bewerken van winkelinformatie',
    formLabel1: 'Afbeelding opslaan',
    formLabel2: 'winkelnaam',
    formLabel3: 'Inlog-e-mail',
    formLabel4: 'uitnodigingscode',
    formLabel7: 'Beschrijving bericht',
    formLabel8: 'Achtergrondafbeelding opslaan',
    formLabel9: 'Marktachtergrondafbeelding',
    fileFail: 'Upload afbeeldingen en formatteer',
    optionsBtn: 'Verzenden',
  },
  'appChangeLoginPass': {
    navTitle: 'Inlogwachtwoord wijzigen',
    formLabel1: 'Oud wachtwoord',
    formLabel2: 'Nieuw wachtwoord',
    formLabel3: 'Verificatiecode',
    formHint1: 'Voer het oude wachtwoord in',
    formHint2: 'Voer een nieuw wachtwoord in',
    formHint3: 'Voer de verificatiecode in',
    formOption: 'Verzenden',
    sendOption: 'Aftellen:',
    optionsBtn: 'Bevestigen',
  },
  'appChangePayPass': {
    navTitle: 'Betaalwachtwoord wijzigen',
    formLabel1: 'Betalingswachtwoord',
    formLabel2: 'Verificatiecode',
    formHint1: 'Voer het betalingswachtwoord in',
    formHint2: 'Voer de verificatiecode in',
    formOption: 'Verzenden',
    sendOption: 'Aftellen:',
    optionsBtn: 'Bevestigen',
  },
  "appFundingRecordsIndex": {
    navTitle: 'Fondsoverzicht',
    searchRecordsTitle: 'Fondstype',
    searchDateTitle: 'Tijdselectie',
    searchFundingLabel1: 'Alle fondsen',
    searchFundingLabel2: 'Stortingstype',
    searchFundingLabel3: 'Opnamerecord',
    searchOptions1: 'Reset',
    searchOptions2: 'Bevestigen',
    searchDateAffirm: 'bevestigen',
    searchDateClose: 'Annuleren',
    stareTimePlace: 'Starttijd',
    endTimePlace: 'Eindtijd',
    typeAll: 'alle',
  },
  "appProjectIndex": {
    navTitle: 'Productlijst',
    searchPlace: 'Voer de productnaam in waarnaar u wilt zoeken',
    shelvesLabel: 'bovenste en onderste planken',
    brandPlace: 'Selecteer een merk',
    classPlace: 'Selecteer een categorie',
    searchOptions1: "Resetten",
    searchOptions2: 'Bevestigen',
    shelvesTag1: "alles",
    shelvesTag2: "op planken",
    shelvesTag3: "uit de schappen",
    salesLabel: 'Verkoop:',
    dialogAffirm: 'Bevestigen',
    dialogClose: 'Annuleren',
    listLabel: 'Schappen omhoog en omlaag:',
  },
  "appProjectLibraryIndex": {
    navTitle: "Productbibliotheek",
    searchPlace: 'Voer de productnaam in waarnaar u wilt zoeken',
    brandPlace: 'Selecteer een merk',
    classPlace: 'Selecteer een categorie',
    searchOptions1: "Resetten",
    searchOptions2: 'Bevestigen',
    salesLabel: 'Verkoop:',
    dialogAffirm: 'Bevestigen',
    dialogClose: 'Annuleren',
    productPackageLabel1: "Product Package",
    productPackageLabel2: '(Product snel toevoegen)',

    selectAll: 'Multiple Choice',
    selectHint: 'Alleen ondersteunt tot 50-producten',
    submitOptions: 'Submit',
    dialogHint: 'Weet je zeker dat je dit product op een batchlijst zet?',
  },
  'appProductPackageIndex': {
    navTitle: "Product Package",
    listLabel1: 'Aanbevolen sterrenclassificatie:',
    listLabel2: 'Synchronization status:',
    listLabel3: "Product Quantity:",
    optionsBtn: "Sync",
    dialogHint: 'Weet je zeker dat je dit productpakket wilt gebruiken?',
    dialogOptions1: 'Annuleren',
    dialogOptions2: "Bevestigen",
    messageToast: 'Inzending succesvol, productinformatie synchronisatie in uitvoering...',
  },
  'appProductPackageList': {
    navTitle: "Product Package Details",
    salesLabel: 'Sales:',
  },
  "appProductFirstIndex": {
    navTitle: 'Productinformatie',
    nextStep: 'Volgende stap',
    label1: 'Productnaam:',
    label2: 'Productmerk:',
    label3: 'Productcategorie:',
    label4: 'Minimale aankoopprijs:',
    label5: 'Minimale verkoopprijs:',
    label6: 'Minimale marktprijs:',
    label7: 'Omslagafbeelding:',
    label8: 'Productfoto:',

  },
  'appProductNextStepIndex': {
    navTitle: 'Productspecificaties',
    previous: 'vorige stap',
    navBtn: 'Verzenden',
    label1: 'Aankoopprijs:',
    placeHint: 'Voer in',
    label2: 'Verkoopprijs:',
    label3: 'Marktprijs:',
    specLabel: 'Specificatie attribuut:',
    optionBtn: "Bevestigen",
    validateMessage1: 'De oorspronkelijke prijs kan niet lager zijn dan de aankoopprijs',
    validateMessage2: 'De oorspronkelijke prijs kan niet lager zijn dan de huidige verkoopprijs',
    validateMessage3: 'De verkoopprijs kan niet lager zijn dan de aankoopprijs',
    validateMessage4: 'De verkoopprijs kan niet hoger zijn dan de oorspronkelijke prijs',
    shelvesTitle: 'Wilt u de planken laden of lossen? ',
    shelvesOptions1: 'Nee',
    shelvesOptions2: 'Ja',
  },
  "appOrderIndex": {
    tagsLabel1: 'Alle',
    orderTotalNumber: 'Totaal ordernummer',
    listLabel1: 'Besteldatum:',
    listLabel2: 'Totale omzet:',
    listLabel3: 'Producthoeveelheid:',
    listLabel4: 'Bestelstatus:',
    listLabel5: 'Betalingsstatus:',
    listHint: 'Aankoop',
    searchOptionBtn: 'Zoeken',
    searchPlace: 'Voer zoekinhoud in',
    searchDateStartPlace: 'Starttijd',
    searchDateEndPlace: 'Eindtijd',
    searchDateAffirm: 'bevestigen',
    searchDateClose: 'Annuleren',
    searchTypePayTitle: 'Betalingsstatus',
    payTags1: 'Alle',
    payTags2: 'Betaling in behandeling',
    payTags3: 'Betaald',
    searchTypeOrderTitle: 'Bestelstatus',
    searchOptions1: 'Reset',
    searchOptions2: 'Bevestigen',
    purDialogTitle: 'Alles kopen',
    purDialogLabel1: 'Aankoopbedrag:',
    purDialogLabel2: 'Verkoopbedrag:',
    purDialogLabel3: 'Winstbedrag:',
    purOptions: "Bevestigen",
    purRowTitle: 'Aankoop',
    purRowLabel1: 'Aankoopbedrag:',
    purRowLabel2: 'Verkoopbedrag:',
    purRowLabel3: 'Winstbedrag:',
  },
  "appOrderDetailIndex": {
    navTitle: 'Bestelgegevens',
    detailInfoLabel1: 'Bestelnummer:',
    detailInfoLabel2: 'Besteltijd:',
    detailInfoLabel3: 'Betaalmethode:',
    detailInfoLabel4: 'Bestelstatus:',
    detailInfoLabel5: 'Betalingsstatus:',
    productLabel1: 'Productinformatie',
    productAttributesLabel: 'Productkenmerken:',
    productAccountLabel1: 'Verkoop:',
    productAccountLabel2: 'Aankoopbedrag:',
    countInfoLabel1: 'Totale omzet:',
    countInfoLabel2: 'Totaal aankoopbedrag:',
    countInfoLabel3: 'Totale winst:',
    countInfoLabel4: 'Totale hoeveelheid:',
    countInfoLabel5: 'Kortingsratio:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Orderlogistiek',
    logisticsLabel: 'Bestelling',
    listLabel1: "Naam:",
    listLabel2: "Telefoon:",
    listLabel3: "Postcode:",
    listLabel4: "Adres:",
  },
  "appOrderDetailRecord": {
    navTitle: 'Bestellingsrecord',
    logisticsLabel: 'Bestelling',
    recordEnumLabel1: 'Klant creëert bestelling',
    recordEnumLabel2: 'Betaalopdracht van klant',
    recordEnumLabel3: 'Winkelbestelling',
    recordEnumLabel4: 'Bestelling wordt verzonden',
    recordEnumLabel5: 'Gebruiker bevestigt ontvangst',
    recordEnumLabel6: 'Systeemterugbetaling arriveert',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Versie-upgrade, bevestig alstublieft? ',
    loadingTitle: 'Downloaden...',
    systemUpgrade: 'Systeemupgrade',
    upgradeImmediately: 'Bevestigen',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Laden",
    finishedText: "Geen",
    loadingText: "Laden",
    nullText: 'Nog geen informatie',
    loadingFinished: "Laden voltooid",
    listNullText: 'Niet meer',
    pullIngText: 'Naar beneden trekken om te vernieuwen',
    loosingText: 'Verversing vrijgeven',
    pullingSuccess: 'Pull-down laden succesvol',
    webSocketSuccess: 'Je hebt een nieuw bericht',
    imLinkSuccess: 'IM-serviceverbinding succesvol! ',
    imNonsupport: 'Deze browser ondersteunt geen websocket',
    imLinkLoading: 'IM is aan het linken, even geduld a.u.b....',
    imLinkErrorLoading: 'IM-verbinding mislukt, vernieuw de pagina of neem contact op met de ontwikkelaar! ',
    dialogHint: 'Heb je al een account?',
    dialogClose: 'Registreren',
    dialogAffirm: 'Login',
  },
};
export default obj;
