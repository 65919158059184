import { render, staticRenderFns } from "./setWithdrawal-index.vue?vue&type=template&id=65616e76&scoped=true"
import script from "./setWithdrawal-index.vue?vue&type=script&lang=js"
export * from "./setWithdrawal-index.vue?vue&type=script&lang=js"
import style0 from "./setWithdrawal-index.vue?vue&type=style&index=0&id=65616e76&prod&lang=scss&scoped=true"
import style1 from "./setWithdrawal-index.vue?vue&type=style&index=1&id=65616e76&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65616e76",
  null
  
)

export default component.exports