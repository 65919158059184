/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean} Boolean
 */
export function validMobile (str) {
  return /^1[3-9][0-9]{9}$/.test(str)
}

/**
 * 检查证电子邮件是否合法
 */
function checkEmail (userEmail, language) {
  if (userEmail == "" || userEmail == null) {
    return { data: false, msg: '请输入邮箱' };
  }
  var mailreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
  if (!mailreg.test(userEmail)) {
    return { data: false, msg: language };
  }
  return { data: true, msg: '' };
}
/**
 * 在此输入密码是否相等
 */
function checkPassword (oldPass, newPass, language1, language2) {
  if (newPass == "" || newPass == null) {
    return { data: false, msg: language1 };
  }

  if (oldPass !== newPass) { 
    return { data: false, msg: language2 };
  }
  return { data: true, msg: '' };
}

export default {
  checkEmail,
  checkPassword
}