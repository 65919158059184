// 俄语
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'Сообщение',
    IMErrorMessage: "Не удалось подключиться к IM.Нажмите, чтобы повторить попытку!",
  },
  "appWebSocketDetails": {
    sendOption: 'Отправить',
    send: 'отправить',
    fileFail: 'Пожалуйста, загрузите и отформатируйте изображения',
    IMportError: "В данный момент соединение не установлено, попробуйте еще раз!",
    IMErrorMessage: "Не удалось подключиться к IM, попробуйте еще раз!",
    retryBtn: "Повторить попытку",
  },
  "appLoginIndex": {
    navTitle: 'Войти',
    projectName: 'Управление магазином',
    formLabel1: 'Электронная почта',
    formLabel2: 'пароль',
    placeholder: "Пожалуйста, введите",
    hint: "Если у вас нет учетной записи",
    clickRegister: 'Нажмите, чтобы зарегистрироваться',
    forgetPass: 'забыл пароль',
    optionBtn: "Войти",
    validateError: 'Пожалуйста, завершите ввод! ',
  },
  "appForgetPassIndex": {
    navTitle: 'Забыли пароль',
    formLabel1: 'Почтовый ящик для входа',
    formLabel2: 'Код подтверждения',
    formLabel3: 'Новый пароль',
    formLabel4: 'Подтвердить пароль',
    formHint1: 'Пожалуйста, введите адрес электронной почты для входа',
    formHint2: 'Пожалуйста, введите новый пароль',
    formHint3: 'Пожалуйста, введите код подтверждения',
    formHint4: "Пожалуйста, введите пароль дважды",
    formOption: "Отправить",
    sendOption: 'Обратный отсчет:',
    optionsBtn: "Подтвердить",
  },
  'footer': {
    home: "Магазин",
    project: "Продукт",
    info: "Сообщение",
    order: 'Заказ',
    user: "Мой",
  },
  'setDrawer': {
    label1: "Многоязычные настройки",
    label2: "Регистрация магазина",
    label3: 'Вход в магазин',
    label4: "Служба поддержки клиентов",
    label5: 'Загрузить приложение для Android',
    label6: 'Загрузить приложение для iOS',
    optionsBtn: "Отмена",
  },
  'appHomeIndex': {
    accountLabel1: "Продажи",
    accountLabel2: 'трафик',
    cardLabel1: 'Обзор данных',
    cardLabel2: 'Отчет о данных',
    cardLabel3: 'Обзор суммы',
    ech1Title: "Общий объем продаж",
    ech2Title: 'Общее количество заказов',
    ech3Title: 'Сохранение трафика',
    echBuoy1Title: "Общий объем продаж",
    echBuoy2Title: 'Общее количество заказов',
    echBuoy3Title: 'Сохранение трафика',
    dataCardTitle1: "Продажи",
    dataCardTitle2: "Трафик",
    dataCardTitle3: 'Общее количество заказов',
    dataCardTitle4: 'Общая сумма задолженности',
    dataCardDayTags: "Сегодня",
    dataYesterTags: "Вчерашние данные",
    dataYesterTags1: "Количество непроверенных заказов",
    cardMenusLabel1: "Налог",
    cardMenusLabel2: 'Сохраненное значение',
    cardMenusLabel3: "Вывод средств",
    cardMenusLabel4: 'Поделиться приглашением',
    cardMenusLabel5: "Список продуктов",
    cardMenusLabel6: 'Список заказов',
    cardMenusLabel7: "Бизнес-отчет",
    taxPopupTitle: 'В настоящее время необходимо платить налоги:',
    popupBtn: 'Иди и заплати налоги',
    cardStateLabel1: "Баланс",
    cardStateLabel2: 'Снятие средств',
    cardStateLabel3: "Доход",
    cardStateLabel4: 'Команда',
    cardSellHotTitle: "10 самых продаваемых товаров",
    salesLabel: 'Продажи:',
  },
  'appSetLanguage': {
    navTitle: 'Многоязычные настройки',
    affirm: 'подтвердить',
  },
  "appServiceIndex": {
    navTitle: "Служба поддержки клиентов",
    customerService: "Эксклюзивное обслуживание клиентов",
    helloTitle: 'Привет',
    welcomeTitle: "Я твой<, вы можете связаться со мной по следующим каналам",
    iconInformation: 'Пожалуйста, выберите коммуникационное программное обеспечение для консультации',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
    kakao: 'KakaoTalk',
    signal: 'Signal'
  },
  'appUserIndex': {
    blanceLabel: 'Баланс',
    blanceOption1: "Вывести",
    blanceOption2: 'Сохранить значение',
    incomeLabel1: "Совокупный доход",
    incomeLabel2: "Накопительные изъятия",
    listLabel1: 'Информация о магазине',
    listLabel2: 'Настройки метода вывода средств',
    listLabel3: 'Запись о фонде',
    listLabel4: "Бизнес- отчет",
    listLabel5: 'Действия по скидкам',
    listLabel7: "Кредит",
    listLabel8: 'Сходить в торговый центр',
    listLabel9: 'Загрузить приложение',
    listLabelLast: 'Выход',
  },
  'downloadAppIndex': {
    navTitle: 'Загрузить',
    downloadAndroidApp: "Загрузка Android",
    downloadIosApp: "Загрузка iOS",
  },
  "appDiscountActivity": {
    navTitle: 'Действия со скидками',
    popupLabel1: 'Сумма',
    popupPlace1: 'Пожалуйста, введите сумму',
    popupLabel2: 'Скидка',
    popupPlace2: 'Пожалуйста, введите скидку',
    popupOption: "Подтвердить",
    listLabel1: 'Необходимая сумма:',
    listLabel2: 'Коэффициент скидки:',
    listLabel3: 'Время скидки:',
    dialogTitle: "Подтвердите удаление!",
    dialogAffirm: 'Подтвердить',
    dialogClose: "Отмена",
  },
  "appInviteIndex": {
    navTitle: 'Пригласить новые действия',
    headerTitle: 'Пригласите друзей, чтобы получить награды',
    copyOption: 'копировать',
    copySuccess: "Копирование успешно",
    inviteTitle: 'Общее количество приглашений',
    inviteLabel1: 'Количество приглашенных:',
    inviteLabel2: 'Общая комиссия:',
    ruleTitle: 'Правило приглашения',
    ruleHint1: ' 1. Приглашающий может пригласить друзей завершить регистрацию, поделившись рекламной ссылкой, и приглашенные друзья будут связаны с вами;',
    ruleHint2: '2. После приглашения пользователей совершить платеж вы можете получить долю от их прибыли;',
    ruleHint3: ' 3. Поддерживаются только награды за приглашения третьего уровня, а соотношение вознаграждений: <%, >%, @%; ',
  },
  "appInviteUserRecordIndex": {
    navTitle: 'Запись приглашения пользователя',
    recordTitle1: 'Общее количество пользователей',
    recordTitle2: 'Уровень 1',
    recordTitle3: "Уровень 2",
    recordTitle4: "Уровень 3",
    tabLabel1: "Все",
    tabLabel2: "Уровень 1",
    tabLabel3: 'Второй уровень',
    tabLabel4: "Уровень 3",
    listLabel1: 'Уровень:',
    listLabel2: 'Прибыль:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: 'Запись дохода от приглашения',
    recordTitle1: "Общий доход",
    recordTitle2: 'Уровень 1',
    recordTitle3: "Уровень 2",
    recordTitle4: "Уровень 3",
    tabLabel1: "Все",
    tabLabel2: "Уровень 1",
    tabLabel3: 'Второй уровень',
    tabLabel4: "Уровень 3",
    listLabel1: 'Прибыль:',
    listLabel2: 'Пропорция:',
  },
  "appWithdrawIndex": {
    navTitle: 'Вывод',
    currencyType1: 'Фиатная валюта',
    currencyType2: "Виртуальная валюта",
    formLabel1: 'Тип валюты:',
    formPlace1: 'Пожалуйста, выберите тип валюты',
    formLabel2: 'Выберите валюту:',
    formPlace2: 'Пожалуйста, выберите тип валюты',
    formLabel3: 'Сумма вывода:',
    formPlace3: 'Пожалуйста, введите сумму вывода',
    formLabel4: 'Пароль для вывода:',
    formPlace4: 'Пожалуйста, введите пароль для вывода:',
    balanceLabel: 'Текущий баланс:',
    amountLabel: 'Фактическое поступление:',
    optionsBtn: "Отправить",
    dialogClose: 'Отмена',
    dialogAffirm: 'Подтвердить',
    currencySelectError: 'Пожалуйста, выберите тип валюты',
    currencyError: 'Пожалуйста, выберите валюту',
    affirmDialogDialogTitle1: 'Вы не установили платежный пароль. Вы хотите это настроить? ',
    affirmDialogDialogTitle2: 'Вы не установили канал вывода средств, хотите его установить? ',
    affirmDialogBtn2: 'Отмена',
    affirmDialogBtn1: 'Подтвердить',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'Настройка метода вывода средств',
    currencyType1: 'Фиатная валюта',
    currencyType2: "Виртуальная валюта",
    formLabel1: 'Тип валюты:',
    formPlace1: 'Пожалуйста, выберите тип валюты',
    formLabel2: 'Выберите валюту:',
    formPlace2: 'Пожалуйста, выберите тип валюты',
    addressLabel: 'Адрес:',
    addressPlace: 'Пожалуйста, введите адрес виртуальной валюты',
    hintLabel: "Важный совет",
    hintValue: 'Передавать только активы ¥',
    optionsBtn: "Отправить",
    dialogClose: 'Отмена',
    dialogAffirm: 'Подтвердить',
    currencySelectError: 'Пожалуйста, выберите тип валюты',
    currencyError: 'Пожалуйста, выберите валюту',
    currencyFail: 'Еще не открыто',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'настоящее имя',
    formPlace1: 'Пожалуйста, введите свое настоящее имя',
    formLabel2: 'Электронная почта',
    formPlace2: 'Пожалуйста, введите свой адрес электронной почты',
    formLabel3: 'Номер мобильного телефона',
    formPlace3: 'Пожалуйста, введите номер вашего мобильного телефона',
    formLabel4: 'Тип PIX',
    formPlace4: 'Пожалуйста, выберите тип PIX',
    formLabel5: 'Учетная запись PIX',
    formPlace5: 'Пожалуйста, введите номер своей учетной записи PIX',
    formLabel6: 'Название банка',
    formPlace6: 'Пожалуйста, введите название банка',
    formLabel7: "Банковский счет",
    formPlace7: 'Пожалуйста, введите банковский счет',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'Страна/регион',
    formPlace1: 'Пожалуйста, введите страну/регион',
    formLabel2: 'имя',
    formPlace2: 'Пожалуйста, введите имя',
    formLabel3: 'Фамилия',
    formPlace3: 'Пожалуйста, введите свою фамилию',
    formLabel4: 'быстрый',
    formPlace4: 'Пожалуйста, введите Swift',
    formLabel5: 'Название банка',
    formPlace5: 'Пожалуйста, введите название банка',
    formLabel6: 'ибан',
    formPlace6: 'Пожалуйста, введите iban',
    formLabel7: 'Номер мобильного телефона',
    formPlace7: 'Пожалуйста, введите номер вашего мобильного телефона',

  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'Страна/регион',
    formPlace1: 'Пожалуйста, введите страну/регион',
    formLabel2: 'имя',
    formPlace2: 'Пожалуйста, введите имя',
    formLabel3: 'Фамилия',
    formPlace3: 'Пожалуйста, введите свою фамилию',
    formLabel4: 'быстрый',
    formPlace4: 'Пожалуйста, введите Swift',
    formLabel5: 'ач',
    formPlace5: 'Пожалуйста, введите ach',
    formLabel6: 'Название банка',
    formPlace6: 'Пожалуйста, введите название банка',
    formLabel7: "Банковский счет",
    formPlace7: 'Пожалуйста, введите банковский счет',
    formLabel8: 'Провинция или штат',
    formPlace8: 'Пожалуйста, укажите провинцию или штат',
    formLabel9: 'Номер мобильного телефона',
    formPlace9: 'Пожалуйста, введите номер вашего мобильного телефона',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'Страна/регион',
    formPlace1: 'Пожалуйста, введите страну/регион',
    formLabel2: 'имя',
    formPlace2: 'Пожалуйста, введите имя',
    formLabel3: 'Фамилия',
    formPlace3: 'Пожалуйста, введите свою фамилию',
    formLabel4: 'Код банка',
    formPlace4: 'Пожалуйста, введите код банка',
    formLabel5: 'Название банка',
    formPlace5: 'Пожалуйста, введите название банка',
    formLabel6: "Банковский счет",
    formPlace6: 'Пожалуйста, введите банковский счет',
    formLabel7: 'Номер мобильного телефона',
    formPlace7: 'Пожалуйста, введите номер вашего мобильного телефона',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'Страна/регион',
    formPlace1: 'Пожалуйста, введите страну/регион',
    formLabel2: 'имя',
    formPlace2: 'Пожалуйста, введите имя',
    formLabel3: 'Фамилия',
    formPlace3: 'Пожалуйста, введите свою фамилию',
    formLabel4: 'зенгин',
    formPlace4: 'Пожалуйста, введите zengin',
    formLabel5: 'Название банка',
    formPlace5: 'Пожалуйста, введите название банка',
    formLabel6: "Банковский счет",
    formPlace6: 'Пожалуйста, введите банковский счет',
    formLabel7: 'Провинция или штат',
    formPlace7: 'Пожалуйста, укажите провинцию или штат',
    formLabel8: 'Номер мобильного телефона',
    formPlace8: 'Пожалуйста, введите номер вашего мобильного телефона',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'Страна/регион',
    formPlace1: 'Пожалуйста, введите страну/регион',
    formLabel2: 'имя',
    formPlace2: 'Пожалуйста, введите имя',
    formLabel3: 'Фамилия',
    formPlace3: 'Пожалуйста, введите свою фамилию',
    formLabel4: 'BSB',
    formPlace4: 'Пожалуйста, введите BSB',
    formLabel5: 'Название банка',
    formPlace5: 'Пожалуйста, введите название банка',
    formLabel6: "Банковский счет",
    formPlace6: 'Пожалуйста, введите банковский счет',
    formLabel7: 'Провинция или штат',
    formPlace7: 'Пожалуйста, укажите провинцию или штат',
    formLabel8: 'Номер мобильного телефона',
    formPlace8: 'Пожалуйста, введите номер вашего мобильного телефона',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'имя',
    formPlace1: 'Пожалуйста, введите имя',
    formLabel2: 'Фамилия',
    formPlace2: 'Пожалуйста, введите свою фамилию',
    formLabel3: 'Название банка',
    formPlace3: 'Пожалуйста, введите название банка',
    formLabel4: "Банковский счет",
    formPlace4: 'Пожалуйста, введите банковский счет',
    formLabel5: 'Номер мобильного телефона',
    formPlace5: 'Пожалуйста, введите номер вашего мобильного телефона',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Название банка',
    formPlace1: 'Пожалуйста, введите название банка',
    formLabel2: 'Учетная запись',
    formPlace2: 'Пожалуйста, введите свою учетную запись',
    formLabel3: 'учетная запись clabe',
    formPlace3: 'Пожалуйста, введите свою учетную запись clabe',
    formLabel4: 'Номер мобильного телефона',
    formPlace4: 'Пожалуйста, введите номер вашего мобильного телефона',
  },
  'appStatisticsReportIndex': {
    navTitle: "Операционный отчет",
    cardLabel1: "Общий объем продаж",
    cardLabel2: 'Общая сумма покупки',
    cardLabel3: "Общая прибыль",
    cardLabel4: 'Общее количество заказов',
    listLabel1: 'Время:',
    listLabel2: 'Заказ:',
    listLabel3: 'Продажи:',
    listLabel4: 'Сумма покупки:',
    profitLabel: 'прибыль',
    searchDateTitle: 'Время документа',
    searchIntervalTitle: 'Интервал времени',
    searchStartTimePlace: 'Время начала',
    searchEndTimePlace: 'Время окончания',
    searchOptions1: "Сброс",
    searchOptions2: "Подтвердить",
    searchDateAffirm: 'подтвердить',
    searchDateClose: 'Отмена',
    searchIntervalLabel1: "Все",
    searchIntervalLabel2: "Сегодня",
    searchIntervalLabel3: 'Вчера',
    searchIntervalLabel4: 'На этой неделе',
    searchIntervalLabel5: 'В этом месяце',
    searchIntervalLabel6: 'В этом году',
    searchIntervalOptions: 'Отмена',
  },
  "appAmountRechargeIndex": {
    navTitle: 'Сохраненное значение',
    currencyType1: 'Фиатная валюта',
    currencyType2: "Виртуальная валюта",
    formLabel1: 'Тип валюты:',
    formPlace1: 'Пожалуйста, выберите тип валюты',
    formLabel2: 'Выберите валюту:',
    formPlace2: 'Пожалуйста, выберите тип валюты',
    formLabel3: 'Сумма сохраненного значения:',
    formPlace3: 'Пожалуйста, введите сумму сохраненного значения',
    formLabel4: 'Метод пополнения счета',
    formPlace4: 'Пожалуйста, выберите способ хранения:',
    balanceLabel: 'Текущий баланс:',
    amountLabel: 'Фактическое поступление:',
    optionsBtn: "Отправить",
    dialogClose: 'Отмена',
    dialogAffirm: 'Подтвердить',
    currencySelectError: 'Пожалуйста, выберите тип валюты',
    currencyError: 'Пожалуйста, выберите валюту',
    successCopy: 'Копировать успешно',
    hintLabel: "Важное напоминание:",
    hintValue: 'Разрешена только передача активов ¥',
    legalHint: 'Пожалуйста, свяжитесь с клиентом!'
  },
  'appShopInfoIndex': {
    navTitle: 'Информация о магазине',
    navEditText: 'Редактировать',
    formLabel1: 'Сохранить изображение',
    formLabel2: 'название магазина',
    formLabel3: 'Электронная почта для входа',
    formLabel4: 'код приглашения',
    formLabel5: 'Пароль для входа',
    formLabel6: 'Платежный пароль',
    formLabel7: 'Описание сообщения',
    formLabel8: 'Сохранить фоновое изображение',
    formLabel9: 'Фоновое изображение рынка',
    formLabel10: 'Информация о сертификате',
    formHint1: 'Лицевая сторона документа',
    formHint2: 'Обратная сторона сертификата',
    fileFail: 'Пожалуйста, загрузите изображения и отформатируйте их',
    optionsBtn: "Отправить",
  },
  'appShopInfoEdit': {
    navTitle: 'Редактор информации о магазине',
    formLabel1: 'Сохранить изображение',
    formLabel2: 'название магазина',
    formLabel3: 'Электронная почта для входа',
    formLabel4: 'код приглашения',
    formLabel7: 'Описание сообщения',
    formLabel8: 'Сохранить фоновое изображение',
    formLabel9: 'Фоновое изображение рынка',
    fileFail: 'Пожалуйста, загрузите изображения и отформатируйте их',
    optionsBtn: "Отправить",
  },
  'appChangeLoginPass': {
    navTitle: 'Изменить пароль для входа',
    formLabel1: 'Старый пароль',
    formLabel2: 'Новый пароль',
    formLabel3: 'Код подтверждения',
    formHint1: 'Пожалуйста, введите старый пароль',
    formHint2: 'Пожалуйста, введите новый пароль',
    formHint3: 'Пожалуйста, введите код подтверждения',
    formOption: "Отправить",
    sendOption: 'Обратный отсчет:',
    optionsBtn: "Подтвердить",
  },
  'appChangePayPass': {
    navTitle: 'Изменить платежный пароль',
    formLabel1: 'Платежный пароль',
    formLabel2: 'Код подтверждения',
    formHint1: "Пожалуйста, введите платежный пароль",
    formHint2: 'Пожалуйста, введите код подтверждения',
    formOption: "Отправить",
    sendOption: 'Обратный отсчет:',
    optionsBtn: "Подтвердить",
  },
  "appFundingRecordsIndex": {
    navTitle: "Запись фонда",
    searchRecordsTitle: 'Тип фонда',
    searchDateTitle: 'Выбор времени',
    searchFundingLabel1: "Все средства",
    searchFundingLabel2: 'Тип депозита',
    searchFundingLabel3: 'Запись о снятии средств',
    searchOptions1: "Сброс",
    searchOptions2: "Подтвердить",
    searchDateAffirm: 'подтвердить',
    searchDateClose: 'Отмена',
    stareTimePlace: 'Время начала',
    endTimePlace: 'Время окончания',
    typeAll: "Все",
  },
  "appProjectIndex": {
    navTitle: 'Список продуктов',
    searchPlace: 'Пожалуйста, введите название продукта, который вы хотите найти',
    shelvesLabel: "верхние и нижние полки",
    brandPlace: 'Пожалуйста, выберите бренд',
    classPlace: 'Пожалуйста, выберите категорию',
    searchOptions1: "Сброс",
    searchOptions2: "Подтвердить",
    shelvesTag1: "Все",
    shelvesTag2: "на полках",
    shelvesTag3: "с полок",
    salesLabel: 'Продажи:',
    dialogAffirm: 'Подтвердить',
    dialogClose: 'Отмена',
    listLabel: 'Полки вверх и вниз:',
  },
  "appProjectLibraryIndex": {
    navTitle: "Библиотека продуктов",
    searchPlace: 'Пожалуйста, введите название продукта, который вы хотите найти',
    brandPlace: 'Пожалуйста, выберите бренд',
    classPlace: 'Пожалуйста, выберите категорию',
    searchOptions1: "Сброс",
    searchOptions2: "Подтвердить",
    salesLabel: 'Продажи:',
    dialogAffirm: 'Подтвердить',
    dialogClose: 'Отмена',
    productPackageLabel1: 'Пакет продуктов',
    productPackageLabel2: '(Быстрое добавление продукта)',

    selectAll: 'Избранные',
    selectHint: 'Поддерживает до 50 продуктов',
    submitOptions: 'Представлено',
    dialogHint: 'Определить, что партия на полке продукта?',
  },
  'appProductPackageIndex': {
    navTitle: "Пакет продуктов",
    listLabel1: "Рекомендуемая звездная шкала:",
    listLabel2: 'Состояние синхронизации:',
    listLabel3: "Количество продуктов:",
    optionsBtn: "Синхронизация",
    dialogHint: "Подтвердите использование этого пакета?",
    dialogOptions1: "Отменить",
    dialogOptions2: "Подтверждение",
    messageToast: "Успешное представление, синхронизация данных продукта...",
  },
  'appProductPackageList': { 
    navTitle: 'Подробности пакета',
    salesLabel: 'Продажа:',
  },
  "appProductFirstIndex": {
    navTitle: "Информация о продукте",
    nextStep: "Следующий шаг",
    label1: 'Название продукта:',
    label2: 'Марка продукта:',
    label3: 'Категория продукта:',
    label4: 'Минимальная цена покупки:',
    label5: 'Минимальная цена продажи:',
    label6: 'Минимальная рыночная цена:',
    label7: 'Обложка:',
    label8: 'Изображение продукта:',

  },
  'appProductNextStepIndex': {
    navTitle: "Технические характеристики продукта",
    previous: "предыдущий шаг",
    navBtn: "Отправить",
    label1: 'Цена покупки:',
    placeHint: "Пожалуйста, введите",
    label2: 'Цена продажи:',
    label3: 'Рыночная цена:',
    specLabel: 'Атрибут спецификации:',
    optionBtn: "Подтвердить",
    validateMessage1: 'Первоначальная цена не может быть ниже цены покупки',
    validateMessage2: 'Исходная цена не может быть ниже текущей цены продажи',
    validateMessage3: 'Цена продажи не может быть ниже цены покупки',
    validateMessage4: 'Цена продажи не может быть выше исходной цены',
    shelvesTitle: "Вы хотите загрузить или выгрузить полки?",
    shelvesOptions1: "Нет",
    shelvesOptions2: "Да",
  },
  "appOrderIndex": {
    tagsLabel1: "Все",
    orderTotalNumber: 'Общее количество заказов',
    listLabel1: 'Дата заказа:',
    listLabel2: 'Общий объем продаж:',
    listLabel3: 'Количество товара:',
    listLabel4: 'Статус заказа:',
    listLabel5: 'Статус платежа:',
    listHint: "Купить",
    searchOptionBtn: 'Поиск',
    searchPlace: 'Пожалуйста, введите содержимое поиска',
    searchDateStartPlace: 'Время начала',
    searchDateEndPlace: 'Время окончания',
    searchDateAffirm: 'подтвердить',
    searchDateClose: 'Отмена',
    searchTypePayTitle: 'Статус платежа',
    payTags1: "Все",
    payTags2: "Ожидание платежа",
    payTags3: "Оплачено",
    searchTypeOrderTitle: 'Статус заказа',
    searchOptions1: "Сброс",
    searchOptions2: "Подтвердить",
    purDialogTitle: 'Купить все',
    purDialogLabel1: 'Сумма покупки:',
    purDialogLabel2: 'Объем продаж:',
    purDialogLabel3: 'Сумма прибыли:',
    purOptions: "Подтвердить",
    purRowTitle: 'Покупка',
    purRowLabel1: 'Сумма покупки:',
    purRowLabel2: 'Объем продаж:',
    purRowLabel3: 'Сумма прибыли:',
  },
  "appOrderDetailIndex": {
    navTitle: 'Детали заказа',
    detailInfoLabel1: 'Номер заказа:',
    detailInfoLabel2: 'Время заказа:',
    detailInfoLabel3: 'Способ оплаты:',
    detailInfoLabel4: 'Статус заказа:',
    detailInfoLabel5: 'Статус платежа:',
    productLabel1: "Информация о продукте",
    productAttributesLabel: 'Атрибуты продукта:',
    productAccountLabel1: 'Продажи:',
    productAccountLabel2: 'Сумма покупки:',
    countInfoLabel1: 'Общий объем продаж:',
    countInfoLabel2: 'Общая сумма покупки:',
    countInfoLabel3: 'Общая прибыль:',
    countInfoLabel4: 'Общее количество:',
    countInfoLabel5: 'Коэффициент скидки:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Логистика заказов',
    logisticsLabel: "Заказ",
    listLabel1: "Имя:",
    listLabel2: "Телефон:",
    listLabel3: "Почтовый индекс:",
    listLabel4: "Адрес:",
  },
  "appOrderDetailRecord": {
    navTitle: 'Запись заказа',
    logisticsLabel: "Заказ",
    recordEnumLabel1: 'Клиент создает заказ',
    recordEnumLabel2: 'Платежное поручение клиента',
    recordEnumLabel3: 'Заказ на покупку в магазине',
    recordEnumLabel4: 'Начинается доставка заказа',
    recordEnumLabel5: 'Пользователь подтверждает получение',
    recordEnumLabel6: 'Системное погашение поступило',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Обновление версии, пожалуйста, подтвердите? ',
    loadingTitle: 'Загрузка...',
    systemUpgrade: "Обновление системы",
    upgradeImmediately: "Подтвердить",
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Загрузка",
    finishedText: "Нет",
    loadingText: "Загрузка",
    nullText: 'Данных пока нет',
    loadingFinished: "Загрузка завершена",
    listNullText: 'Больше нет',
    pullIngText: 'Потяните вниз, чтобы обновить',
    loosingText: 'Отпустите для обновления',
    pullingSuccess: 'Последующая загрузка прошла успешно',
    webSocketSuccess: "У вас новое сообщение",
    imLinkSuccess: 'Подключение к службе обмена мгновенными сообщениями выполнено успешно! ',
    imNonsupport: 'Этот браузер не поддерживает веб-сокеты',
    imLinkLoading: 'IM подключается, пожалуйста, подождите...',
    imLinkErrorLoading: "Не удалось подключиться к IM, обновите страницу или свяжитесь с разработчиком!",
    dialogHint: 'Есть ли у вас учетная запись?',
    dialogClose: 'Регистрация',
    dialogAffirm: 'Войти',
  },
};
export default obj;
